import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Banner from "../../Components/Banner/Banner";

import ReactTable from "../../Components/ReactTable/ReactTable";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { CubeSpinner } from "../../Components/Spinners/Spinners";
import { ErrorAlert } from "../../Components/Alerts/Alerts";

// import SearchFilter from "../../Components/SearchFilter/SearchFilter";
// import isEmpty from "validator/es/lib/isEmpty";
import { filterByVal, getStandardErrMsg } from "../../utils/utils";
import {
  useDeleteModelMutation,
  useGetModelsQuery,
} from "../../services/modelsService";
import { useSelector } from "react-redux";
import ContentWrapper from "../../Components/ContentWrapper/ContentWrapper";
import FillterWrapper from "../../Components/TableFilterWrapper/TableFillterWrapper";
import useConfirmDelete from "../../utils/useConfirmDelete";
import useFilteredData from "../../utils/useFilteredData";
import AccessWrapper from "../../Components/AccessWrapper/AccessWrapper";
import "react-responsive-iframe-viewer/dist/style.css";
import {
  ResponsiveIframeViewer,
  ViewportSize,
} from "react-responsive-iframe-viewer";
import Iframe from "react-iframe";
import { dasboardLink } from "../../constants/constants";

const MySwal = withReactContent(Swal);

const AnalyticsScreen = () => {
  const navigate = useNavigate();
  const currentSpace = useSelector((state) => state.defaultSpace.data);

  const [postsPerPage, setPostPerPage] = useState(10);
  const [searchText, setSearchText] = useState("");
  // const [filteredPosts, setFilteredPosts] = useState([]);

  const { data, error, isFetching } = useGetModelsQuery(currentSpace);
  const [deleteModel] = useDeleteModelMutation();
  const confirmDelete = useConfirmDelete(deleteModel);

  const handleDelete = useCallback(
    (id) => {
      console.log("🚀 ~ ModelsScreen ~ id:", id);

      confirmDelete(id);
    },
    [confirmDelete]
  );

  // const handleDelete = useCallback(
  //   (user_email) => {
  //     MySwal.fire({
  //       title: "Are you sure?",
  //       text: "You won't be able to revert this!",
  //       icon: "warning",
  //       showCancelButton: true,
  //       confirmButtonColor: "#3085d6",
  //       cancelButtonColor: "#d33",
  //       confirmButtonText: "Delete",
  //     }).then((result) => {
  //       if (result.isConfirmed) {
  //         deleteModel(user_email);
  //       }
  //     });
  //   },
  //   [deleteModel]
  // );

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "model_id",
      },
      {
        Header: "Model",
        accessor: "model",
      },
      {
        Header: "Desc",
        accessor: "description",
      },
      {
        Header: "Actions",
        Cell: ({ cell }) => (
          <>
            <AccessWrapper hasAccess="edit">
              <Link
                to={`/model/edit/${cell.row.original.model_id}`}
                className="text-body"
              >
                <i className="ti ti-edit ti-sm me-2" />
              </Link>
            </AccessWrapper>
            <AccessWrapper hasAccess="delete">
              <Link
                to="#"
                className="text-body delete-record"
                onClick={() => handleDelete(cell.row.original.model_id)}
              >
                <i className="ti ti-trash ti-sm mx-2" />
              </Link>
            </AccessWrapper>
          </>
        ),
      },
    ],
    [handleDelete]
  );

  const filteredPosts = useFilteredData(data, searchText, filterByVal);

  const handleAddNew = () => {
    navigate("/model/new");
  };

  return (
    <ContentWrapper>
      <Banner>Analytics</Banner>
      <div className={`dropdown-header d-flex align-items-center py-3 mt-5`}>
        <IframeWithLoader src={dasboardLink} />
      </div>
    </ContentWrapper>
  );
};

const ResponsiveIframe = ({ src }) => {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight * 0.8, // 80% of viewport height
  });
  const [isIframeLoaded, setIframeLoaded] = useState(false); // Track iframe load state

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight * 0.8, // Recalculate height dynamically
      });
    };

    // Add resize event listener
    window.addEventListener("resize", handleResize);

    // Initial calculation
    handleResize();

    // Cleanup on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleIframeLoad = () => {
    setIframeLoaded(true); // Hide loader once iframe loads
  };

  return (
    <div style={{ position: "relative", height: `${dimensions.height}px` }}>
      {/* Loader */}
      {!isIframeLoaded && (
        <div
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(255, 255, 255, 0.8)", // Semi-transparent overlay
            zIndex: 1,
          }}
        >
          <div className="spinner"></div>
        </div>
      )}

      <ResponsiveIframeViewer
        src={src}
        title="Rick Astley - Never Gonna Give You Up"
        size={ViewportSize.fluid}
      />
    </div>
  );
};

const IframeWithLoader = ({ src }) => {
  const [isLoading, setIsLoading] = useState(true);

  const handleIframeLoad = () => {
    setIsLoading(false); // Stop showing loader when iframe loads
  };

  return (
    <div style={{ position: "relative", height: "500px", width: "100%" }}>
      {/* Loader */}
      {isLoading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            zIndex: 1,
          }}
        >
          <div>Loading document...</div>
        </div>
      )}

      {/* Iframe */}
      <Iframe
        url={src}
        width="100%"
        height="100%"
        frameBorder="0"
        onLoad={handleIframeLoad} // Fires when iframe content is fully loaded
      />
    </div>
  );
};

export default AnalyticsScreen;
