import "./App.css";
import Aside from "./Components/Aside/Aside";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import UserManagement from "./Screens/UserManagement/UserManagement";
import Navbar from "./Components/Navbar/Navbar";
import ManageSpace from "./Screens/ManageSpace/ManageSpace";
import SelectSpace from "./Screens/SelectSpace/SelectSpace";
import ModelsScreen from "./Screens/Models/Models";
import AddNewModel from "./Screens/Models/AddNewModel";
import DatabaseScreen from "./Screens/Databases/Database";
import AddNewDatabase from "./Screens/Databases/AddNewDatabase";
import ActionsScreen from "./Screens/Actions/Actions";
import AddNewAction from "./Screens/Actions/AddNewActions";
import AgentScreen from "./Screens/Agents/Agents";
import AddNewAgent from "./Screens/Agents/AddNewAgent";
import { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { clearCookies, userAccess, getCookieByDomain } from "./utils/utils";
import { loginPath } from "./constants/constants";
import Cookies from "js-cookie";
import AiWorkforceScreen from "./Screens/AiWorkforce/AiWorkforce";
import AddNewWorkforce from "./Screens/AiWorkforce/AddNewWorkforce";
import PageNotFound from "./Components/PageNotFound/PageNotFound";
import CollectionsScreen from "./Screens/Collections/Collections";
import AddNewCollection from "./Screens/Collections/AddNewCollection";
import CollectionFilesScreen from "./Screens/Collections/CollectionFiles";
import ApiKeys from "./Screens/ApiKeys/ApiKeys";
import AnalyticsScreen from "./Screens/Analytics/Analytics";

function isObjectEmpty(obj) {
  return Object.keys(obj).length === 0;
}

function App() {
  const [autoToggleEnabled, setAutoToggleEnabled] = useState(
    Cookies.get("isExpanded") === "true" ? false : true || true
  );
  const [isAuth, setIsAuth] = useState(false);
  const space = useSelector((state) => state.defaultSpace.data);
  const userAccess = useSelector((state) => state.userAccess);

  const isLogout = useMemo(() => window.location.pathname === "/logout", []);

  useEffect(() => {
    if ((!isAuth && !isLogout) || isObjectEmpty(userAccess)) {
      const accessToken = getCookieByDomain("crave_jwt_access_token");
      if (!accessToken) {
        console.log("step 2");

        clearCookies();
        window.location.href = loginPath;
      } else {
        console.log("step 3");

        setIsAuth(true);
      }
    }
  }, [isAuth, isLogout, userAccess]);

  return (
    <>
      {isAuth && (
        <Router>
          <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
              <Aside
                autoToggleEnabled={autoToggleEnabled}
                setAutoToggleEnabled={setAutoToggleEnabled}
              />
              <div
                className={`${
                  autoToggleEnabled ? "compressed-layout-page" : "layout-page"
                }`}
              >
                <Navbar />
                <Routes>
                  <Route path="/user-management" element={<UserManagement />} />
                  <Route path="/manage-space" element={<ManageSpace />} />

                  <Route path="/space/select-space" element={<SelectSpace />} />

                  {space && (
                    <>
                      <Route path="/models" element={<ModelsScreen />} />
                      <Route path="/model/new" element={<AddNewModel />} />
                      <Route
                        path="/model/edit/:model_id"
                        element={<AddNewModel />}
                      />

                      {/* Databases */}

                      <Route path="/database" element={<DatabaseScreen />} />
                      <Route
                        path="/database/new"
                        element={<AddNewDatabase />}
                      />
                      <Route
                        path="/database/edit/:database_id"
                        element={<AddNewDatabase />}
                      />

                      {/* Actions */}
                      <Route path="/actions" element={<ActionsScreen />} />
                      <Route path="/action/new" element={<AddNewAction />} />
                      <Route
                        path="/action/edit/:action_id"
                        element={<AddNewAction />}
                      />

                      {/* Agents */}
                      <Route path="/agents" element={<AgentScreen />} />
                      <Route path="/analytics" element={<AnalyticsScreen />} />

                      <Route path="/agent/new" element={<AddNewAgent />} />
                      <Route
                        path="/agent/edit/:agent_id"
                        element={<AddNewAgent />}
                      />

                      {/* Workforce */}
                      <Route
                        path="/ai-workforce"
                        element={<AiWorkforceScreen />}
                      />
                      <Route
                        path="/ai-workforce/new"
                        element={<AddNewWorkforce />}
                      />
                      <Route
                        path="/ai-workforce/edit/:workforce_id"
                        element={<AddNewWorkforce />}
                      />

                      {/* Collections */}
                      <Route
                        path="/collections"
                        element={<CollectionsScreen />}
                      />
                      <Route
                        path="/collection/new"
                        element={<AddNewCollection />}
                      />
                      <Route
                        path="/collection/view/:collection_id"
                        element={<CollectionFilesScreen />}
                      />

                      <Route
                        path="/environmental-variables"
                        element={<ApiKeys />}
                      />
                    </>
                  )}

                  {/* Main Again */}
                  <Route exact path="/" element={<SelectSpace />} />
                  <Route path="*" component={PageNotFound} />
                </Routes>
              </div>
            </div>
          </div>
        </Router>
      )}
    </>
  );
}

export default App;
